
import { Component, Vue, Prop } from "vue-property-decorator";
import Clock from "@/components/quick-info/Clock.vue";

@Component({
  components: {
    Clock,
  },
})
export default class QuickInfo extends Vue {
  @Prop({ type: String, required: false })
  private title?: string;

  public get isXl(): boolean {
    // (this as any) necassary because of the missing types for vue-mq
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).$mq === "xl";
  }
}
