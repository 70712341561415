import { StationInfo } from "@/entities/app-state/StationInfo";
import { apiClient } from "./apiClient";

export default class StationInfoService {
  private get baseUrl(): string {
    return "/api/station-info";
  }

  public async getStationsForCoordinates(latitude: number, longitude: number): Promise<StationInfo[]> {
    try {
      const res = await apiClient.get<StationInfo[]>(this.baseUrl.toString(), {
        params: {
          latitude,
          longitude,
        },
      });

      return res.data;
    } catch {
      return [];
    }
  }
}
