import { PartnerScreenSettings } from "@/entities/app-state/PartnerScreenSettings";
import { apiClient } from "./apiClient";
import { AxiosResponse } from "axios";

export default class SettingsService {
  public async getSettings(): Promise<PartnerScreenSettings | null> {
    try {
      const res: AxiosResponse<PartnerScreenSettings> = await apiClient.get<PartnerScreenSettings>("/api/settings");
      return res.data;
    } catch {
      return null;
    }
  }
}
