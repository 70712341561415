import "@/assets/styles/main.css";
import "vue-class-component/hooks";
import Vue from "vue";
import Vuelidate from "vuelidate";
import VueMq from "vue-mq";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Fragment from "vue-fragment";

Vue.config.productionTip = false;

// Validation for vue components
Vue.use(Vuelidate);

// Setup vue-mq which enables easy detection of breakpoints by using the css variables defined by bootstrap
Vue.use(VueMq, {
  breakpoints: {
    xs: parseInt(getComputedStyle(document.body).getPropertyValue("--breakpoint-sm").replace("px", ""), 10),
    sm: parseInt(getComputedStyle(document.body).getPropertyValue("--breakpoint-md").replace("px", ""), 10),
    md: parseInt(getComputedStyle(document.body).getPropertyValue("--breakpoint-lg").replace("px", ""), 10),
    lg: parseInt(getComputedStyle(document.body).getPropertyValue("--breakpoint-xl").replace("px", ""), 10),
    xl: Infinity,
  },
});

// Select component with search functionality
Vue.component("v-select", vSelect);

// multi root vue components "fragments"
Vue.use(Fragment.Plugin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
