
import { Configuration } from "@/entities/app-state/Configuration";
import { LoadingState } from "@/entities/LoadingState";
import { MonitorService } from "@/services/MonitorService";
import { Actions, Getters } from "@/store/constants";
import { isNullOrWhitespace } from "@/util/isNullOrWhitespace";
import { notNullOrWhitespace } from "@/util/notNullOrWhitespace";
import Vue from "vue";
import { warn } from "vue-class-component/lib/util";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class ErrorView extends Vue {
  private reconnectRefreshRate = 300000; // 5 min
  private reconnectInvervalRefId: number | null = null;

  private readonly monitorService: MonitorService = new MonitorService();

  private get errorReason(): string {
    const reason = this.$route.params.reason;
    return reason;
  }

  private get config(): string {
    return this.$route.params.config;
  }

  private get showReturnToConfigButton(): boolean {
    return this.errorReason == "licence";
  }

  private get shouldTryToReconnect(): boolean {
    return isNullOrWhitespace(this.errorReason) && notNullOrWhitespace(this.config);
  }

  private get configurationLoadingState(): LoadingState {
    return this.$store.getters[Getters.CONFIGURATION_LOADING_STATE];
  }

  private get configuration(): Configuration {
    return this.$store.getters[Getters.CONFIGURATION];
  }

  @Watch("configurationLoadingState")
  private onConfigurationLoaded(loadingState: LoadingState): void {
    if (loadingState === LoadingState.Loaded && this.reconnectInvervalRefId == null && this.configuration?.partnerKey) {
      this.reconnectInvervalRefId = window.setInterval(() => {
        this.monitorService.isAlive(this.configuration.partnerKey).then((isAlive) => {
          if (isAlive) {
            this.$router.push(`/monitor/${this.config}`);
          }
        });
      }, this.reconnectRefreshRate);
    } else {
      setTimeout(() => this.reloadConfiguration(), 5000);
    }
  }

  private returnToConfig(): void {
    this.$router.push("/");
  }

  private reloadConfiguration(): void {
    if (this.configurationLoadingState !== LoadingState.Loading && notNullOrWhitespace(this.config)) {
      this.$store.dispatch(Actions.LOAD_CONFIGURATION, { configurationId: this.config });
    }
  }

  public created(): void {
    if (this.shouldTryToReconnect) {
      this.reloadConfiguration();
    }
  }

  public beforeDestroy(): void {
    if (this.reconnectInvervalRefId) {
      window.clearInterval(this.reconnectInvervalRefId);
    }
  }
}
