
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { notNullOrWhitespace } from "@/util/notNullOrWhitespace";
import { Actions, Getters } from "@/store/constants";

@Component
export default class PartnerLogoUpload extends Vue {
  @Ref("fileInput")
  private readonly fileInput!: HTMLInputElement;

  private fileTooLarge = false;

  private get partnerLogoUrl(): string {
    return this.$store.getters[Getters.PARTNER_LOGO_URL] ?? "";
  }

  private uploadFile(): void {
    const imageFile: File | null | undefined = this.fileInput.files?.item(0);

    if (imageFile) {
      if (imageFile.size >= 2000000) {
        this.fileTooLarge = true;
        return;
      }

      this.$store.dispatch(Actions.UPDATE_PARTNER_LOGO, { file: imageFile });
      this.fileTooLarge = false;
    }
  }

  private deletePartnerLogo(): void {
    this.fileTooLarge = false;
    this.$store.dispatch(Actions.DELETE_PARTNER_LOGO);
  }

  private get showPrevieImage(): boolean {
    return notNullOrWhitespace(this.partnerLogoUrl);
  }

  private get showDeleteButton(): boolean {
    return this.showPrevieImage;
  }

  private get partnerKey(): string {
    return this.$store.getters[Getters.PARTNER_KEY];
  }

  @Watch("partnerKey")
  private onPartnerKeyChanged(): void {
    this.$store.dispatch(Actions.LOAD_PARTNER_LOGO);
  }

  created(): void {
    this.$store.dispatch(Actions.LOAD_PARTNER_LOGO);
  }
}
