// eslint-disable-next-line
export const Actions = {
  LOAD_CONFIGURATION: "LOAD_CONFIGURATION",
  LOAD_MONITOR_INFO: "LOAD_MONITOR_INFO",
  LOAD_DISTURBANCE_INFO: "LOAD_DISTURBANCE_INFO",
  UPDATE_PLACE: "UPDATE_PLACE",
  LOAD_SETTINGS: "LOAD_SETTINGS",
  SUBMIT_CONFIGURATION_FORM: "SUBMIT_CONFIGURATION_FORM",
  SEND_LICENCE_REQUEST: "SEND_LICENCE_REQUEST",
  UPDATE_PARTNER_KEY: "UPDATE_PARTNER_KEY",
  UPDATE_PARTNER_LOGO: "UPDATE_PARTNER_LOGO",
  LOAD_PARTNER_LOGO: "LOAD_PARTNER_LOGO",
  DELETE_PARTNER_LOGO: "DELETE_PARTNER_LOGO",
  VALIDATE_CONFIGURATION: "VALIDATE_CONFIGURATION",
};

// eslint-disable-next-line
export const Mutations = {
  RESET_CONFIGURATION_FORM: "RESET_CONFIGURATION_FORM",
  RESET_MONITOR: "RESET_MONITOR",
  UPDATE_TITLE: "UPDATE_TITLE",
  UPDATE_PLACE: "UPDATE_PLACE",
  LOAD_NEARBY_STATIONS: "LOAD_NEARBY_STATIONS",
  LOAD_NEARBY_STATIONS_SUCCESS: "LOAD_NEARBY_STATIONS_SUCCESS",
  LOAD_NEARBY_STATIONS_FAILURE: "LOAD_NEARBY_STATIONS_FAILURE",
  UPDATE_STATIONS: "UPDATE_STATIONS",
  UPDATE_PARTNER_KEY: "UPDATE_PARTNER_KEY",
  UPDATE_PARTNER_KEY_IS_VALID: "UPDATE_PARTNER_KEY_IS_VALID",
  UPDATE_CONFIGURATION_FORM_IS_VALID: "UPDATE_CONFIGURATION_FORM_IS_VALID",
  GOOGLE_MAPS_IS_LOADED: "GOOGLE_MAPS_IS_LOADED",
  LOAD_SETTINGS: "LOAD_SETTINGS",
  LOAD_SETTINGS_SUCCESS: "LOAD_SETTINGS_SUCCESS",
  LOAD_SETTINGS_FAILURE: "LOAD_SETTINGS_FAILURE",
  LOAD_CONFIGURATION: "LOAD_CONFIGURATION",
  LOAD_CONFIGURATION_SUCCESS: "LOAD_CONFIGURATION_SUCCESS",
  LOAD_CONFIGURATION_FAILURE: "LOAD_CONFIGURATION_FAILURE",
  UPDATE_MONITOR_INFO: "UPDATE_MONITOR_INFO",
  UPDATE_STATION_LOCATION_INFO: "UPDATE_STATION_LOCATION_INFO",
  UPDATE_DISTURBANCE_INFO: "UPDATE_DISTURBANCE_INFO",
  INCREASE_REQUEST_ERROR_COUNT: "INCREASE_REQUEST_ERROR_COUNT",
  INCREASE_LICENCE_ERROR_COUNT: "INCREASE_LICENCE_ERROR_COUNT",
  RESET_REQUEST_ERROR_COUNT: "RESET_REQUEST_ERROR_COUNT",
  RESET_LICENCE_ERROR_COUNT: "RESET_LICENCE_ERROR_COUNT",
  RESET_MONITOR_ID: "RESET_MONITOR_ID",
  UPDATE_SHOW_THIRD_DEPARTURE: "UPDATE_SHOW_THIRD_DEPARTURE",
  UPDATE_PARTNER_LOGO: "UPDATE_PARTNER_LOGO",
  UPDATE_PARTNER_LOGO_FAILED: "UPDATE_PARTNER_LOGO_FAILED",
  RESET_PARTNER_LOGO: "RESET_PARTNER_LOGO",
  UPDATE_LAST_LICENCE_CHECK_DATE: "UPDATE_LAST_LICENCE_CHECK_DATE",
};

// eslint-disable-next-line
export const Getters = {
  TITLE: "TITLE",
  PLACE: "PLACE",
  SELECTED_STATIONS: "SELECTED_STATIONS",
  PARTNER_KEY: "PARTNER_KEY",
  IS_PARTNER_KEY_VALID: "IS_PARTNER_KEY_VALID",
  IS_CONFIGURATION_FORM_VALID: "IS_CONFIGURATION_FORM_VALID",
  IS_GOOGLE_MAPS_LOADED: "IS_GOOGLE_MAPS_LOADED",
  NEARBY_STATIONS: "NEARBY_STATIONS",
  NEARBY_STATIONS_LOADING_STATE: "NEARBY_STATIONS_LOADING_STATE",
  SETTINGS_LOADING_STATE: "SETTINGS_LOADING_STATE",
  SETTINGS: "SETTINGS",
  CONFIGURATION: "CONFIGURATION",
  CONFIGURATION_LOADING_STATE: "CONFIGURATION_LOADING_STATE",
  MONITOR_INFO: "MONITOR_INFO",
  STATION_LOCATION_INFO: "STATION_LOCATION_INFO",
  REQUEST_ERROR_COUNT: "REQUEST_ERROR_COUNT",
  LICENCE_ERROR_COUNT: "LICENCE_ERROR_COUNT",
  DISTURBANCE_INFO: "DISTURBANCE_INFO",
  MONITOR_ID: "MONITOR_ID",
  SHOW_THIRD_DEPARTURE: "SHOW_THIRD_DEPARTURE",
  PARTNER_LOGO_URL: "PARTNER_LOGO_URL",
  UPDATE_PARTNER_LOGO_FAILED: "UPDATE_PARTNER_LOGO_FAILED",
  LAST_LICENCE_CHECK_DATE: "LAST_LICENCE_CHECK_DATE",
};
