
import Vue from "vue";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import { required, minLength, maxLength, and } from "vuelidate/lib/validators";
import PlaceAutocomplete from "@/components/place-autocomplete/PlaceAutocomplete.vue";
import StationSelect from "@/components/station-select/StationSelect.vue";
import { Getters, Mutations, Actions } from "@/store/constants";
import { SelectedStations } from "@/entities/app-state/SelectedStations";
import { Place } from "@/entities/app-state/Place";
import { StationInfo } from "@/entities/app-state/StationInfo";
import { LoadingState } from "@/entities/LoadingState";
import PartnerLogoUpload from "@/components/partner-logo/PartnerLogoUpload.vue";

@Component({
  components: {
    PlaceAutocomplete,
    StationSelect,
    PartnerLogoUpload,
  },
  mixins: [validationMixin],
  validations: {
    title: { required, length: maxLength(35) },
    place: { required },
    stations: {
      1: { required },
      2: {},
    },
    partnerKey: { required, length: and(minLength(8), maxLength(8)) },
  },
})
export default class Start extends Vue {
  private readonly departureOptions = [
    {
      code: 2,
      label: "2 Abfahrtszeiten",
    },
    {
      code: 3,
      label: "3 Abfahrtszeiten",
    },
  ];

  public mounted(): void {
    this.$store.commit(Mutations.RESET_MONITOR_ID);
  }

  public beforeDestroy(): void {
    this.$store.commit(Mutations.RESET_CONFIGURATION_FORM);
  }

  public submitConfiguration(): void {
    if (this.isConfigurationValid) {
      this.$store.dispatch(Actions.SUBMIT_CONFIGURATION_FORM);
    }
  }

  public get isPartnerKeyValid(): boolean {
    return this.$v.partnerKey.length && (this.$store.getters[Getters.IS_PARTNER_KEY_VALID] ?? true);
  }

  public get showPartnerLogoUpload(): boolean {
    return this.isPartnerKeyValid && (this.partnerKey?.length > 0 ?? false);
  }

  public get isFormValid(): boolean {
    return this.$store.getters[Getters.IS_CONFIGURATION_FORM_VALID] ?? true;
  }

  public get title(): string {
    return this.$store.getters[Getters.TITLE];
  }

  public set title(title: string) {
    this.$store.commit(Mutations.UPDATE_TITLE, { title });
    this.$store.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
      isValid: null,
    });
  }

  public get showThirdDeparture(): boolean {
    return this.$store.getters[Getters.SHOW_THIRD_DEPARTURE];
  }

  public get selectedDeparture(): number {
    return this.showThirdDeparture ? 3 : 2;
  }

  public updateShowThirdDeparture(value: number): void {
    const showThirdDeparture = value == 3;
    this.$store.commit(Mutations.UPDATE_SHOW_THIRD_DEPARTURE, { show: showThirdDeparture });
  }

  public get place(): Place | null {
    return this.$store.getters[Getters.PLACE];
  }

  public selectPlace(place: Place | null): void {
    this.$store.dispatch(Actions.UPDATE_PLACE, { place });
    this.$store.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
      isValid: null,
    });
  }

  public get nearbyStations(): StationInfo[] {
    return this.$store.getters[Getters.NEARBY_STATIONS];
  }

  public get areNearbyStationsLoading(): boolean {
    return this.$store.getters[Getters.NEARBY_STATIONS_LOADING_STATE] === LoadingState.Loading;
  }

  public get partnerKey(): string {
    return this.$store.getters[Getters.PARTNER_KEY];
  }

  public set partnerKey(partnerKey: string) {
    this.$store.dispatch(Actions.UPDATE_PARTNER_KEY, { partnerKey });
    this.$store.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
      isValid: null,
    });
  }

  public get stations(): SelectedStations {
    return this.$store.getters[Getters.SELECTED_STATIONS];
  }

  public selectStation(value: StationInfo | null, index: number): void {
    this.$v.stations[index]?.$touch();
    this.$store.commit(Mutations.UPDATE_STATIONS, {
      stations: { ...this.stations, [index]: value },
    });
  }

  public get isConfigurationValid(): boolean {
    return !this.$v.$invalid && this.isFormValid;
  }
}
