import Vue from "vue";
import Vuex, { ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { AppState } from "@/entities/app-state/AppState";
import { Actions, Mutations, Getters } from "./constants";
import { SelectedStations } from "@/entities/app-state/SelectedStations";
import { Place } from "@/entities/app-state/Place";
import StationInfoService from "@/services/StationInfoService";
import { StationInfo } from "@/entities/app-state/StationInfo";
import { LoadingState } from "@/entities/LoadingState";
import SettingsService from "@/services/SettingsService";
import { PartnerScreenSettings } from "@/entities/app-state/PartnerScreenSettings";
import { MonitorService } from "@/services/MonitorService";
import { MonitorInfo } from "@/entities/monitor/MonitorInfo";
import { StationLocationInfo } from "@/entities/map/StationLocationInfo";
import { CreateConfigurationRequest } from "@/services/entities/CreateConfigurationRequest";
import { isNullOrWhitespace } from "@/util/isNullOrWhitespace";
import ConfigurationService from "@/services/ConfigurationService";
import { AuthenticationError } from "@/services/entities/AuthenticationError";
import router from "@/router";
import { Configuration } from "@/entities/app-state/Configuration";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { LicenceService } from "@/services/LicenceService";
import { PartnerLogoService } from "@/services/PartnerLogoService";
import { ApiErrors } from "@/services/entities/ApiErrors";

Vue.use(Vuex);

const appState: AppState = {
  settings: null,
  settingsLoadingState: LoadingState.Indeterminate,
  configurationForm: {
    title: "",
    place: null,
    nearbyStations: [],
    nearbyStationsLoadingState: LoadingState.Indeterminate,
    selectedStations: {},
    showThirdDeparture: false,
    partnerKey: null,
    partnerKeyIsValid: null,
    formIsValid: null,
    partnerLogoUrl: null,
    partnerLogoUploadFailed: false,
  },
  configuration: null,
  configurationLoadingState: LoadingState.Indeterminate,
  monitorInfo: null,
  stationLocationInfo: null,
  disturbances: null,
  googleMapsLoaded: false,
  requestErrorCount: 0,
  licenceRequestErrorCount: 0,
  lastLicenceCheckDate: null,
};

// services
const monitorService: MonitorService = new MonitorService();
const stationInfoService: StationInfoService = new StationInfoService();
const settingsService: SettingsService = new SettingsService();
const configurationService = new ConfigurationService();
const licenceService = new LicenceService();
const partnerLogoService = new PartnerLogoService();

const actions: ActionTree<AppState, AppState> = {
  async [Actions.UPDATE_PLACE](
    context: ActionContext<AppState, AppState>,
    payload: { place: Place | null }
  ): Promise<void> {
    context.commit(Mutations.UPDATE_PLACE, { place: payload.place });

    context.commit(Mutations.LOAD_NEARBY_STATIONS);
    if (payload.place == null) {
      context.commit(Mutations.LOAD_NEARBY_STATIONS_FAILURE);
      return;
    }

    try {
      const stations = await stationInfoService.getStationsForCoordinates(
        payload.place.latitude,
        payload.place.longitude
      );
      if (stations.length === 0) {
        context.commit(Mutations.LOAD_NEARBY_STATIONS_FAILURE);
        return;
      }
      context.commit(Mutations.LOAD_NEARBY_STATIONS_SUCCESS, { stations });
    } catch {
      context.commit(Mutations.LOAD_NEARBY_STATIONS_FAILURE);
    }
  },
  async [Actions.LOAD_CONFIGURATION](
    context: ActionContext<AppState, AppState>,
    payload: { configurationId: string }
  ): Promise<void> {
    context.commit(Mutations.LOAD_CONFIGURATION);
    try {
      const configuration = await configurationService.getConfiguration(payload.configurationId);
      context.commit(Mutations.LOAD_CONFIGURATION_SUCCESS, { configuration });
    } catch (e) {
      if (e.message == ApiErrors.ConfigurationBreakingChangeError || e.message == ApiErrors.InvalidConfigurationError) {
        window.location.href = "/";
      }
      context.commit(Mutations.LOAD_CONFIGURATION_FAILURE);
    }
  },
  async [Actions.LOAD_MONITOR_INFO](context: ActionContext<AppState, AppState>): Promise<void> {
    if (context.state.configuration == null) {
      context.commit(Mutations.INCREASE_REQUEST_ERROR_COUNT);
      return;
    }
    const configuration = context.state.configuration;
    try {
      const monitorInfo = await monitorService.getMonitorInfo(
        configuration.partnerKey,
        context.getters[Getters.MONITOR_ID],
        configuration.stationIds,
        configuration.numberOfShownDepartures
      );
      context.commit(Mutations.UPDATE_MONITOR_INFO, { monitorInfo });

      const stationLocationInfo: StationLocationInfo[] = await monitorService.getStationLocationInfo(
        configuration.partnerKey,
        context.getters[Getters.MONITOR_ID],
        configuration.stationIds,
        configuration.place
      );
      context.commit(Mutations.UPDATE_STATION_LOCATION_INFO, { stationLocationInfo });
      context.commit(Mutations.RESET_REQUEST_ERROR_COUNT);
      context.commit(Mutations.RESET_LICENCE_ERROR_COUNT);
    } catch (err) {
      if (err.response?.status === 401) {
        context.commit(Mutations.INCREASE_LICENCE_ERROR_COUNT);
      } else {
        context.commit(Mutations.INCREASE_REQUEST_ERROR_COUNT);
      }
    }
  },
  async [Actions.LOAD_DISTURBANCE_INFO](context: ActionContext<AppState, AppState>): Promise<void> {
    if (context.state.configuration == null) {
      context.commit(Mutations.INCREASE_REQUEST_ERROR_COUNT);
      return;
    }
    const configuration = context.state.configuration;
    try {
      const disturbances = await monitorService.getDisturbanceInfo(
        configuration.partnerKey,
        context.getters[Getters.MONITOR_ID],
        configuration.stationIds
      );
      context.commit(Mutations.UPDATE_DISTURBANCE_INFO, { disturbances });
      context.commit(Mutations.RESET_REQUEST_ERROR_COUNT);
      context.commit(Mutations.RESET_LICENCE_ERROR_COUNT);
    } catch (err) {
      if (err.response?.status === 401) {
        context.commit(Mutations.INCREASE_LICENCE_ERROR_COUNT);
      } else {
        context.commit(Mutations.INCREASE_REQUEST_ERROR_COUNT);
      }
    }
  },
  async [Actions.LOAD_SETTINGS](context: ActionContext<AppState, AppState>): Promise<void> {
    context.commit(Mutations.LOAD_SETTINGS);
    try {
      const settings = await settingsService.getSettings();
      if (settings == null) {
        context.commit(Mutations.LOAD_SETTINGS_FAILURE);
        return;
      }
      context.commit(Mutations.LOAD_SETTINGS_SUCCESS, { settings });
    } catch {
      context.commit(Mutations.LOAD_SETTINGS_FAILURE);
    }
  },
  async [Actions.SUBMIT_CONFIGURATION_FORM](context: ActionContext<AppState, AppState>): Promise<void> {
    const config = context.state.configurationForm;

    if (isNullOrWhitespace(config.partnerKey)) {
      context.commit(Mutations.UPDATE_PARTNER_KEY_IS_VALID, { isValid: false });
      return;
    }

    if (config.place == null) {
      context.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
        isValid: false,
      });
      return;
    }
    if (Object.values(config.selectedStations).length === 0) {
      context.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
        isValid: false,
      });
      return;
    }

    const createConfigurationRequest: CreateConfigurationRequest = {
      title: config.title,
      partnerKey: config.partnerKey as string,
      stationIds: Object.values(config.selectedStations).map((stationInfo: StationInfo) => stationInfo.id),
      numberOfShownDepartures: context.state.configurationForm.showThirdDeparture ? 3 : 2,
      place: {
        latitude: config.place.latitude,
        longitude: config.place.longitude,
      },
    };

    try {
      const configurationId = await configurationService.createConfiguration(createConfigurationRequest);
      context.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
        isValid: true,
      });
      context.commit(Mutations.UPDATE_PARTNER_KEY_IS_VALID, {
        isValid: true,
      });
      router.push({ name: "monitor", params: { configurationId } });
      return;
    } catch (e) {
      if (e instanceof Error && AuthenticationError.isAuthenticationError(e)) {
        context.commit(Mutations.UPDATE_PARTNER_KEY_IS_VALID, {
          isValid: false,
        });
      }
    }
    context.commit(Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID, {
      isValid: false,
    });
  },
  async [Actions.SEND_LICENCE_REQUEST](context: ActionContext<AppState, AppState>): Promise<void> {
    const partnerKey = context.state.configuration?.partnerKey;
    const monitorId = context.getters[Getters.MONITOR_ID];

    if (partnerKey) {
      await licenceService.sendLicenceRequest(partnerKey, monitorId);
      context.commit(Mutations.UPDATE_LAST_LICENCE_CHECK_DATE, { date: new Date() });
    }
  },
  async [Actions.UPDATE_PARTNER_KEY](
    context: ActionContext<AppState, AppState>,
    payload: { partnerKey: string }
  ): Promise<void> {
    context.commit(Mutations.UPDATE_PARTNER_KEY, { partnerKey: payload.partnerKey });
    if (isNullOrWhitespace(payload.partnerKey) || payload.partnerKey.length !== 8) {
      context.commit(Mutations.UPDATE_PARTNER_KEY_IS_VALID, { isValid: false });
      return;
    }

    const isValid = await licenceService.isValidPartnerKey(payload.partnerKey);
    context.commit(Mutations.UPDATE_PARTNER_KEY_IS_VALID, { isValid });
  },
  async [Actions.UPDATE_PARTNER_LOGO](
    context: ActionContext<AppState, AppState>,
    payload: { file: File }
  ): Promise<void> {
    const partnerKey = context.state.configurationForm.partnerKey;

    if (isNullOrWhitespace(partnerKey)) {
      return;
    }

    try {
      const imageUrl = await partnerLogoService.uploadPartnerLogo(payload.file, partnerKey ?? "");
      context.commit(Mutations.UPDATE_PARTNER_LOGO, { imageUrl });
    } catch (e) {
      context.commit(Mutations.UPDATE_PARTNER_LOGO_FAILED);
    }
  },
  async [Actions.LOAD_PARTNER_LOGO](context: ActionContext<AppState, AppState>): Promise<void> {
    const partnerKey = context.state.configurationForm.partnerKey ?? context.state.configuration?.partnerKey;

    if (isNullOrWhitespace(partnerKey)) {
      return;
    }

    try {
      const partnerLogoInfo = await partnerLogoService.getPartnerLogoInfo(partnerKey ?? "");
      const imageUrl = partnerLogoService.getPartnerLogoImageUrl(partnerLogoInfo.id);
      context.commit(Mutations.UPDATE_PARTNER_LOGO, { imageUrl });
    } catch (e) {
      context.commit(Mutations.RESET_PARTNER_LOGO);
    }
  },
  async [Actions.DELETE_PARTNER_LOGO](context: ActionContext<AppState, AppState>): Promise<void> {
    const partnerKey = context.state.configurationForm.partnerKey;

    if (isNullOrWhitespace(partnerKey)) {
      return;
    }

    await partnerLogoService.deletePartnerLogo(partnerKey ?? "");
    context.commit(Mutations.RESET_PARTNER_LOGO);
  },
  async [Actions.VALIDATE_CONFIGURATION](
    context: ActionContext<AppState, AppState>,
    payload: { configurationId: string }
  ): Promise<void> {
    try {
      await configurationService.getConfiguration(payload.configurationId);
    } catch (e) {
      if (e.message == ApiErrors.ConfigurationBreakingChangeError || e.message == ApiErrors.InvalidConfigurationError) {
        window.location.href = "/";
      }
    }
  },
};

const mutations: MutationTree<AppState> = {
  [Mutations.RESET_CONFIGURATION_FORM](state): void {
    state.configurationForm = {
      title: "",
      place: null,
      nearbyStations: [],
      nearbyStationsLoadingState: LoadingState.Indeterminate,
      selectedStations: {},
      showThirdDeparture: false,
      partnerKey: null,
      partnerKeyIsValid: null,
      formIsValid: null,
      partnerLogoUrl: null,
      partnerLogoUploadFailed: false,
    };
  },
  [Mutations.RESET_MONITOR](state): void {
    state.configuration = null;
    state.configurationLoadingState = LoadingState.Indeterminate;
    state.monitorInfo = null;
    state.stationLocationInfo = null;
    state.disturbances = null;
  },
  [Mutations.RESET_MONITOR_ID](state): void {
    licenceService.deleteMonitorId();
  },
  [Mutations.UPDATE_TITLE](state, payload: { title: string }): void {
    state.configurationForm.title = payload.title;
  },
  [Mutations.UPDATE_PLACE](state, payload: { place: Place | null }): void {
    state.configurationForm.place = payload.place;
  },
  [Mutations.UPDATE_STATIONS](state, payload: { stations: SelectedStations }): void {
    state.configurationForm.selectedStations = payload.stations;
  },
  [Mutations.LOAD_NEARBY_STATIONS](state) {
    state.configurationForm.nearbyStationsLoadingState = LoadingState.Loading;
    state.configurationForm.selectedStations = {};
  },
  [Mutations.LOAD_NEARBY_STATIONS_SUCCESS](state, payload: { stations: StationInfo[] }) {
    state.configurationForm.nearbyStationsLoadingState = LoadingState.Loaded;
    state.configurationForm.nearbyStations = payload.stations;
  },
  [Mutations.LOAD_NEARBY_STATIONS_FAILURE](state) {
    state.configurationForm.nearbyStationsLoadingState = LoadingState.Failed;
    state.configurationForm.nearbyStations = [];
  },
  [Mutations.UPDATE_PARTNER_KEY](state, payload: { partnerKey: string }): void {
    state.configurationForm.partnerKey = payload.partnerKey;
  },
  [Mutations.UPDATE_PARTNER_KEY_IS_VALID](state, payload: { isValid: boolean | null }): void {
    state.configurationForm.partnerKeyIsValid = payload.isValid;
  },
  [Mutations.UPDATE_CONFIGURATION_FORM_IS_VALID](state, payload: { isValid: boolean | null }): void {
    state.configurationForm.formIsValid = payload.isValid;
  },
  [Mutations.GOOGLE_MAPS_IS_LOADED](state): void {
    state.googleMapsLoaded = true;
  },
  [Mutations.LOAD_SETTINGS](state): void {
    state.settingsLoadingState = LoadingState.Loading;
  },
  [Mutations.LOAD_SETTINGS_SUCCESS](state, payload: { settings: PartnerScreenSettings }): void {
    state.settingsLoadingState = LoadingState.Loaded;
    state.settings = payload.settings;
  },
  [Mutations.LOAD_SETTINGS_FAILURE](state): void {
    state.settingsLoadingState = LoadingState.Failed;
    state.settings = null;
  },
  [Mutations.LOAD_CONFIGURATION](state: AppState): void {
    state.configurationLoadingState = LoadingState.Loading;
  },
  [Mutations.LOAD_CONFIGURATION_SUCCESS](state: AppState, payload: { configuration: Configuration }): void {
    state.configurationLoadingState = LoadingState.Loaded;
    state.configuration = payload.configuration;
  },
  [Mutations.LOAD_CONFIGURATION_FAILURE](state: AppState): void {
    state.configurationLoadingState = LoadingState.Failed;
  },
  [Mutations.UPDATE_MONITOR_INFO](state: AppState, payload: { monitorInfo: MonitorInfo }): void {
    state.monitorInfo = payload.monitorInfo;
  },
  [Mutations.UPDATE_STATION_LOCATION_INFO](
    state: AppState,
    payload: { stationLocationInfo: StationLocationInfo[] }
  ): void {
    state.stationLocationInfo = payload.stationLocationInfo;
  },
  [Mutations.UPDATE_DISTURBANCE_INFO](state: AppState, payload: { disturbances: DisturbanceInfo[] }): void {
    state.disturbances = payload.disturbances;
  },
  [Mutations.INCREASE_REQUEST_ERROR_COUNT](state: AppState): void {
    state.requestErrorCount++;
  },
  [Mutations.RESET_REQUEST_ERROR_COUNT](state: AppState): void {
    state.requestErrorCount = 0;
  },
  [Mutations.INCREASE_LICENCE_ERROR_COUNT](state: AppState): void {
    state.licenceRequestErrorCount++;
  },
  [Mutations.RESET_LICENCE_ERROR_COUNT](state: AppState): void {
    state.licenceRequestErrorCount = 0;
  },
  [Mutations.UPDATE_SHOW_THIRD_DEPARTURE](state: AppState, payload: { show: boolean }): void {
    state.configurationForm.showThirdDeparture = payload.show;
  },
  [Mutations.UPDATE_PARTNER_LOGO](state: AppState, payload: { imageUrl: string }): void {
    state.configurationForm.partnerLogoUrl = payload.imageUrl;
    state.configurationForm.partnerLogoUploadFailed = false;
  },
  [Mutations.UPDATE_PARTNER_LOGO_FAILED](state: AppState): void {
    state.configurationForm.partnerLogoUploadFailed = true;
  },
  [Mutations.RESET_PARTNER_LOGO](state: AppState): void {
    state.configurationForm.partnerLogoUrl = "";
    state.configurationForm.partnerLogoUploadFailed = false;
  },
  [Mutations.UPDATE_LAST_LICENCE_CHECK_DATE](state: AppState, payload: { date: Date }): void {
    state.lastLicenceCheckDate = payload.date;
  },
};

const getters: GetterTree<AppState, AppState> = {
  [Getters.TITLE](currentState: AppState): string {
    return currentState.configurationForm.title;
  },
  [Getters.PLACE](currentState: AppState): Place | null {
    return currentState.configurationForm.place;
  },
  [Getters.SELECTED_STATIONS](currentState: AppState): SelectedStations {
    return currentState.configurationForm.selectedStations;
  },
  [Getters.NEARBY_STATIONS](currentState: AppState): StationInfo[] {
    return currentState.configurationForm.nearbyStations;
  },
  [Getters.NEARBY_STATIONS_LOADING_STATE](currentState: AppState): LoadingState {
    return currentState.configurationForm.nearbyStationsLoadingState;
  },
  [Getters.PARTNER_KEY](currentState: AppState): string | null {
    return currentState.configurationForm.partnerKey;
  },
  [Getters.IS_PARTNER_KEY_VALID](currentState: AppState): boolean | null {
    return currentState.configurationForm.partnerKeyIsValid;
  },
  [Getters.IS_CONFIGURATION_FORM_VALID](currentState: AppState): boolean | null {
    return currentState.configurationForm.formIsValid;
  },
  [Getters.IS_GOOGLE_MAPS_LOADED](currentState: AppState): boolean {
    return currentState.googleMapsLoaded;
  },
  [Getters.SETTINGS_LOADING_STATE](currentState: AppState): LoadingState {
    return currentState.settingsLoadingState;
  },
  [Getters.SETTINGS](currentState: AppState): PartnerScreenSettings | null {
    return currentState.settings;
  },
  [Getters.CONFIGURATION](currentState: AppState): Configuration | null {
    return currentState.configuration;
  },
  [Getters.CONFIGURATION_LOADING_STATE](currentState: AppState): LoadingState | null {
    return currentState.configurationLoadingState;
  },
  [Getters.MONITOR_INFO](currentState: AppState): MonitorInfo | null {
    return currentState.monitorInfo;
  },
  [Getters.STATION_LOCATION_INFO](currentState: AppState): StationLocationInfo[] | null {
    return currentState.stationLocationInfo;
  },
  [Getters.REQUEST_ERROR_COUNT](currentState: AppState): number {
    return currentState.requestErrorCount;
  },
  [Getters.LICENCE_ERROR_COUNT](currentState: AppState): number {
    return currentState.licenceRequestErrorCount;
  },
  [Getters.DISTURBANCE_INFO](currentState: AppState): DisturbanceInfo[] | null {
    return currentState.disturbances;
  },
  [Getters.MONITOR_ID](currentState: AppState): string {
    return licenceService.generateMonitorIdIfNotExists();
  },
  [Getters.SHOW_THIRD_DEPARTURE](currentState: AppState): boolean {
    return currentState.configurationForm.showThirdDeparture;
  },
  [Getters.PARTNER_LOGO_URL](currentState: AppState): string | null {
    return currentState.configurationForm.partnerLogoUrl;
  },
  [Getters.UPDATE_PARTNER_LOGO_FAILED](currentState: AppState): boolean {
    return currentState.configurationForm.partnerLogoUploadFailed;
  },
  [Getters.LAST_LICENCE_CHECK_DATE](currentState: AppState): Date | null {
    return currentState.lastLicenceCheckDate;
  },
};

export default new Vuex.Store({
  state: appState,
  actions,
  mutations,
  getters,
  strict: process.env.NODE_ENV !== "production",
});
