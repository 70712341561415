import { PartnerLogoInfo } from "@/entities/app-state/PartnerLogoInfo";
import axios, { AxiosResponse } from "axios";
import { authenticatedApiClient } from "./apiClient";

export class PartnerLogoService {
  private baseUrl(): string {
    return "/api/configurations";
  }

  public async uploadPartnerLogo(image: File, partnerKey: string): Promise<string> {
    const formData = new FormData();
    formData.append("fileInput", image);

    const rsp = await axios.post(`${this.baseUrl()}/partner-logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Api-Key": partnerKey,
      },
    });

    return rsp.headers.location;
  }

  public async getPartnerLogoInfo(partnerKey: string): Promise<PartnerLogoInfo> {
    const rsp: AxiosResponse<PartnerLogoInfo> = await authenticatedApiClient(partnerKey).get<PartnerLogoInfo>(
      `${this.baseUrl()}/partner-logo-info`
    );
    return rsp.data;
  }

  public getPartnerLogoImageUrl(id: string): string {
    return `${this.baseUrl()}/partner-logo?id=${id}`;
  }

  public async deletePartnerLogo(partnerKey: string): Promise<void> {
    await authenticatedApiClient(partnerKey).post(`${this.baseUrl()}/delete-partner-logo`);
  }
}
