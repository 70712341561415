
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { StationInfo } from "@/entities/app-state/StationInfo";

@Component
export default class StationSelect extends Vue {
  @Prop()
  private readonly selectedStation!: StationInfo | null;
  @Prop()
  private readonly stations!: StationInfo[];

  @Emit("station-select")
  public emitStationSelect(station: StationInfo | null): StationInfo | null {
    return station;
  }
}
