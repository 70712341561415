import { CreateConfigurationRequest } from "./entities/CreateConfigurationRequest";
import { authenticatedApiClient, isAxiosError, apiClient } from "./apiClient";
import { CreateConfigurationResponse } from "./entities/CreateConfigurationResponse";
import { ApiErrors } from "./entities/ApiErrors";
import { ValidationError } from "./entities/ValidationError";
import { ProblemDetails } from "./entities/ErrorResponse";
import { Configuration } from "@/entities/app-state/Configuration";

export default class ConfigurationService {
  private get baseUrl(): string {
    return "/api/configurations";
  }

  public async createConfiguration(request: CreateConfigurationRequest): Promise<string> {
    try {
      const res = await authenticatedApiClient(request.partnerKey).post<CreateConfigurationResponse>(
        this.baseUrl,
        request
      );
      return res.data.configurationId;
    } catch (e) {
      if (!isAxiosError<ProblemDetails>(e)) {
        throw e;
      }

      if (e.response?.status === 401) {
        throw new Error(ApiErrors.AuthenticationError);
      }

      if (e.response?.status === 400) {
        throw new ValidationError(e.response.data);
      }

      throw e;
    }
  }

  public async getConfiguration(configurationId: string): Promise<Configuration> {
    try {
      const res = await apiClient.get<Configuration>(this.baseUrl, { params: { id: configurationId } });
      return res.data;
    } catch (e) {
      console.log(e.response.status);
      if (e.response?.status == 406) {
        throw new Error(ApiErrors.ConfigurationBreakingChangeError);
      }

      if (e.response?.status == 400) {
        throw new Error(ApiErrors.InvalidConfigurationError);
      }

      throw e;
    }
  }
}
