
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Impress from "@/components/info-components/Impress.vue";
import Accessibility from "@/components/info-components/Accessibility.vue";

@Component({
  components: {
    Impress,
    Accessibility,
  },
})
export default class InfoView extends Vue {
  private get infoReason(): string {
    const info = this.$route.params.info;
    return info;
  }

  private returnToConfig(): void {
    this.$router.push("/");
  }
}
