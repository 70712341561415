
import { Component, Vue, Ref, Emit, Watch } from "vue-property-decorator";
import { Getters } from "@/store/constants";
import { Place } from "@/entities/app-state/Place";
import { isNullOrWhitespace } from "@/util/isNullOrWhitespace";

@Component
export default class PlaceAutocomplete extends Vue {
  @Ref("autocomplete")
  private readonly autocompleteRef!: HTMLInputElement;

  @Emit("place-select")
  public emitPlaceChange(place: Place | null): Place | null {
    return place;
  }

  private autocompleteAttached = false;
  private autocomplete?: google.maps.places.Autocomplete;

  private get isGoogleMapsLoaded(): boolean {
    return this.$store.getters[Getters.IS_GOOGLE_MAPS_LOADED];
  }

  @Watch("isGoogleMapsLoaded")
  private onGoogleMapsLoaded(isLoaded: boolean): void {
    if (!this.autocompleteAttached && isLoaded) {
      this.attachAutocomplete();
    }
  }

  public mounted(): void {
    if (!this.autocompleteAttached && this.isGoogleMapsLoaded) {
      this.attachAutocomplete();
    }
  }

  public beforeDestroy(): void {
    if (this.autocomplete) {
      this.autocomplete.unbindAll();
    }
  }

  private attachAutocomplete(): void {
    this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteRef, {
      componentRestrictions: { country: "at" },
    });
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.autocompleteAttached = true;
  }

  private onValueInput(value: string | null): void {
    if (isNullOrWhitespace(value)) {
      this.emitPlaceChange(null);
    }
  }

  private onPlaceChanged(): void {
    const placeResult = this.autocomplete?.getPlace();
    if (placeResult == null) {
      this.emitPlaceChange(null);
      return;
    }

    const location = placeResult.geometry?.location;
    if (location == null) {
      this.emitPlaceChange(null);
      return;
    }

    const place: Place = {
      name: placeResult.name,
      address: placeResult.formatted_address ?? "",
      latitude: location.lat(),
      longitude: location.lng(),
    };

    this.emitPlaceChange(place);
  }
}
