import { authenticatedApiClient } from "./apiClient";
import { v4 as uuidv4 } from "uuid";

/**
 * Service for handling licence validation
 */
export class LicenceService {
  private get baseUrl(): string {
    return "/api/licence";
  }

  public async sendLicenceRequest(partnerKey: string, monitorId: string): Promise<void> {
    await authenticatedApiClient(partnerKey).post<void>(`${this.baseUrl}/requests`, {
      partnerKey: partnerKey,
      monitorId: monitorId,
    });
  }

  private monitorIdSessionStorageName(): string {
    return "monitor-id";
  }

  public generateMonitorIdIfNotExists(): string {
    const existingMonitorId = sessionStorage.getItem(this.monitorIdSessionStorageName());

    if (existingMonitorId) {
      return existingMonitorId;
    }

    const monitorId = uuidv4();
    sessionStorage.setItem(this.monitorIdSessionStorageName(), monitorId);

    return monitorId;
  }

  public deleteMonitorId(): void {
    sessionStorage.removeItem(this.monitorIdSessionStorageName());
  }

  public async isValidPartnerKey(partnerKey: string): Promise<boolean> {
    try {
      await authenticatedApiClient(partnerKey).get<void>(`${this.baseUrl}/is-valid`);
      return true;
    } catch (e) {
      return false;
    }
  }
}
