import axios, { AxiosError, AxiosInstance } from "axios";

const defaultHeaders = {
  Accept: "application/json",
};

export const apiClient = axios.create({
  headers: defaultHeaders,
});

export const authenticatedApiClient = (apiKey: string): AxiosInstance =>
  axios.create({
    headers: {
      ...defaultHeaders,
      ["Api-Key"]: apiKey,
    },
  });

export const authenticatedLicencedApiClient = (apiKey: string, monitorId: string): AxiosInstance =>
  axios.create({
    headers: {
      ...defaultHeaders,
      ["Api-Key"]: apiKey,
      ["Monitor-Id"]: monitorId,
    },
  });

export function isAxiosError<T>(error: { isAxiosError?: boolean }): error is AxiosError<T> {
  return error.isAxiosError ?? false;
}
