import { ApiErrors } from "./ApiErrors";
import { ProblemDetails } from "./ErrorResponse";

export class ValidationError extends Error {
  constructor(problemDetails: ProblemDetails) {
    super(ApiErrors.ValidationError);
    this._problemDetails = problemDetails;
  }

  private readonly _problemDetails: ProblemDetails;
  public get problemDetails(): ProblemDetails {
    return this._problemDetails;
  }

  public static isValidationError(error: Error): error is ValidationError {
    return error.message === ApiErrors.ValidationError;
  }
}
