import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Start from "../views/Start.vue";
import Error from "../views/Error.vue";
import Info from "../views/Info.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Start",
    component: Start,
  },
  {
    path: "/monitor/:configurationId",
    name: "monitor",
    // route level code-splitting
    // this generates a separate chunk (monitor.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "monitor" */ "../views/Monitor.vue"),
  },
  {
    path: "/error/:config/:reason?",
    name: "Error",
    component: Error,
  },
  {
    path: "/info/:info?",
    name: "Info",
    component: Info,
  },
];

const router: VueRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
