
import { Component, Vue } from "vue-property-decorator";
@Component
export default class Clock extends Vue {
  private hours = "";
  private minutes = "";
  private intervalRefId: number | null = null;

  private getHours(date: Date): string {
    return date.getHours().toString().padStart(2, "0");
  }

  private getMinutes(date: Date): string {
    return date.getMinutes().toString().padStart(2, "0");
  }

  private updateClock(): void {
    const date = new Date();
    this.hours = this.getHours(date);
    this.minutes = this.getMinutes(date);
  }

  public created(): void {
    this.updateClock();
    this.intervalRefId = window.setInterval(() => {
      this.updateClock();
    }, 1000);
  }

  public beforeDestroy(): void {
    if (this.intervalRefId != null) {
      window.clearInterval(this.intervalRefId);
    }
  }
}
