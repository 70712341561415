import { authenticatedApiClient, authenticatedLicencedApiClient } from "./apiClient";
import { MonitorInfo } from "@/entities/monitor/MonitorInfo";
import { AxiosResponse } from "axios";
import { StationLocationInfo } from "@/entities/map/StationLocationInfo";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { IsAlive, IsAliveStatus } from "@/entities/monitor/IsAlive";
import { Place } from "@/entities/app-state/Place";

/**
 * Service to retrieve station departure infos for monitor screen.
 */
export class MonitorService {
  private get baseUrl(): string {
    return "/api/monitor";
  }

  public async getMonitorInfo(
    partnerKey: string,
    monitorId: string,
    stationIds: string[],
    numberOfShownDepartures: number
  ): Promise<MonitorInfo> {
    const params = this.buildUrlParams(stationIds);
    params.append("numberOfShownDepartures", numberOfShownDepartures.toString());
    const res: AxiosResponse<MonitorInfo> = await authenticatedLicencedApiClient(partnerKey, monitorId).get<
      MonitorInfo
    >(this.baseUrl, {
      params,
    });

    return res.data;
  }

  public async getStationLocationInfo(
    partnerKey: string,
    monitorId: string,
    stationIds: string[],
    place: Place
  ): Promise<StationLocationInfo[]> {
    const params = this.buildUrlParams(stationIds, place);
    const res: AxiosResponse<StationLocationInfo[]> = await authenticatedLicencedApiClient(partnerKey, monitorId).get<
      StationLocationInfo[]
    >(`${this.baseUrl}/location`, {
      params,
    });

    return res.data;
  }

  public async getDisturbanceInfo(
    partnerKey: string,
    monitorId: string,
    stationIds: string[]
  ): Promise<DisturbanceInfo[]> {
    const params = this.buildUrlParams(stationIds);
    const res: AxiosResponse<DisturbanceInfo[]> = await authenticatedLicencedApiClient(partnerKey, monitorId).get<
      DisturbanceInfo[]
    >(`${this.baseUrl}/disturbance`, { params });

    return res.data;
  }

  public async isAlive(partnerKey: string): Promise<boolean> {
    try {
      const res: AxiosResponse<IsAlive> = await authenticatedApiClient(partnerKey).get(`${this.baseUrl}/isAlive`);
      return res.data.status === IsAliveStatus.Available;
    } catch {
      return false;
    }
  }

  private buildUrlParams(stationIds: string[], place?: Place): URLSearchParams {
    const params = new URLSearchParams();
    stationIds.forEach((element) => {
      params.append("stationIds", element);
    });
    if (place) {
      params.append("latitude", place.latitude.toString());
      params.append("longitude", place.longitude.toString());
    }
    return params;
  }
}
