import { ApiErrors } from "./ApiErrors";

export class AuthenticationError extends Error {
  constructor() {
    super(ApiErrors.AuthenticationError);
  }

  public static isAuthenticationError(error: Error): error is AuthenticationError {
    return error.message === ApiErrors.AuthenticationError;
  }
}
