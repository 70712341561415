
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import QuickInfo from "@/components/quick-info/QuickInfo.vue";
import { Mutations, Actions, Getters } from "./store/constants";
import { LoadingState } from "./entities/LoadingState";
import { PartnerScreenSettings } from "./entities/app-state/PartnerScreenSettings";

@Component({
  components: {
    QuickInfo,
  },
})
export default class App extends Vue {
  public created(): void {
    this.$store.dispatch(Actions.LOAD_SETTINGS);
  }

  public get settingsLoadingState(): LoadingState {
    return this.$store.getters[Getters.SETTINGS_LOADING_STATE];
  }

  public get areSettingsLoading(): boolean {
    return this.settingsLoadingState == LoadingState.Loading;
  }

  public get settings(): PartnerScreenSettings | null {
    return this.$store.getters[Getters.SETTINGS];
  }

  public get title(): string | null {
    return this.$store.getters[Getters.CONFIGURATION]?.title ?? this.$store.getters[Getters.TITLE];
  }

  @Watch("settingsLoadingState")
  private async addMapsScript(loadingState: LoadingState): Promise<void> {
    if (loadingState !== LoadingState.Loaded || this.settings == null) {
      return;
    }

    const callbackName = "vueGoogleMapsInitCallback";
    const apiKey = this.settings.googleMaps.key;
    const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?callback=${callbackName}&libraries=places,geometry&key=${apiKey}`;
    if (document.querySelectorAll(`[src="${googleMapsUrl}"]`).length > 0) {
      this.$store.commit(Mutations.GOOGLE_MAPS_IS_LOADED);
      return;
    }

    await new Promise((resolve) => {
      // eslint-disable-next-line
      (window as any)[callbackName] = resolve;
      const googleMapsScriptTag = document.createElement("script");
      googleMapsScriptTag.setAttribute("src", googleMapsUrl);
      document.body.appendChild(googleMapsScriptTag);
    });

    this.$store.commit(Mutations.GOOGLE_MAPS_IS_LOADED);
  }
}
